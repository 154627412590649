/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 30, 2023 */
@font-face {
  font-family: Nexa Extra Light;
  src: url("nexa-extralight-webfont.f41616cc.woff2") format("woff2"), url("nexa-extralight-webfont.1a235990.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Nexa Heavy;
  src: url("nexa-heavy-webfont.6d2e3f8e.woff2") format("woff2"), url("nexa-heavy-webfont.8e6da3ea.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  src: url("ubuntu_medium.caeed7be.ttf") format("truetype");
}

@font-face {
  font-family: American Typewriter;
  font-style: normal;
  src: url("american_typewriter.64996497.ttc") format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  color: #fff;
  background: #d10007;
  margin: 0;
  padding-bottom: 100px;
  font-family: Nexa Extra Light, sans-serif;
}

body.embedded {
  background-color: #000;
}

a, a:visited {
  color: #fff;
}

#sharing .fb_iframe_widget span {
  vertical-align: baseline !important;
}

#game-parent {
  -webkit-user-select: none;
  user-select: none;
  height: 56.25vw;
  max-height: calc(100vh - 50px);
  position: relative;
}

:-webkit-any(#game-parent:fullscreen, #game-parent:-webkit-full-screen, #game-parent:fullscreen, #game-parent:fullscreen) {
  max-height: unset;
}

:-moz-any(#game-parent:fullscreen, #game-parent:fullscreen, #game-parent:-moz-full-screen, #game-parent:fullscreen) {
  max-height: unset;
}

:is(#game-parent:fullscreen, #game-parent:fullscreen, #game-parent:fullscreen, #game-parent:-ms-fullscreen) {
  max-height: unset;
}

:is(#game-parent:fullscreen, #game-parent:fullscreen, #game-parent:fullscreen, #game-parent:fullscreen) {
  max-height: unset;
}

#game-parent:fullscreen canvas, #game-parent:-webkit-full-screen canvas, #game-parent:-moz-full-screen canvas, #game-parent:-ms-fullscreen canvas {
  max-height: 100vh;
  top: 50%;
  transform: translate(-50%, -50%);
}

#game-parent canvas {
  background-color: #40afc2;
  outline: none;
  height: 56.25vw;
  max-height: calc(100vh - 50px);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.embedded #game-parent {
  background-color: unset;
  background-image: unset;
  height: 56.25vw;
  max-height: 100vh;
}

.embedded #game-parent canvas {
  height: 56.25vw;
  max-height: 100vh;
}

#html-layer {
  transform-origin: 0 0;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: relative;
}

#html-layer > * {
  pointer-events: auto;
  color: #fff;
  font-family: Jura;
}

#html-layer input[type="radio"] {
  width: 20px;
  height: 20px;
}

#descriptive-content {
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  display: flex;
}

@media (width >= 768px) {
  #descriptive-content {
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
    display: flex;
  }
}

#descriptive-content * {
  margin: 0;
}

@media (width >= 768px) {
  #descriptive-content > :first-child {
    text-align: left;
  }

  #descriptive-content > :nth-child(2) {
    text-align: right;
  }
}

#browser-warning {
  font-size: small;
}

.build-info {
  font-size: x-small;
}

#intro-video {
  border: 4px solid #fff;
  border-radius: 100px;
  margin: 50px 240px;
}

#play-button {
  cursor: pointer;
  background: url("play.538d318c.png") no-repeat;
  width: 220px;
  height: 220px;
  position: absolute;
  top: 350px;
  left: 830px;
}

/*# sourceMappingURL=index.be96d332.css.map */
