/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 30, 2023 */
@font-face {
  font-family: "Nexa Extra Light";
  src: url("../fonts/nexa-extralight-webfont.woff2") format("woff2"),
    url("../fonts/nexa-extralight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Nexa Heavy";
  src: url("../fonts/nexa-heavy-webfont.woff2") format("woff2"),
    url("../fonts/nexa-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  src: url("../fonts/ubuntu_medium.ttf") format("truetype");
}

@font-face {
  font-family: "American Typewriter";
  font-style: normal;
  src: url("../fonts/american_typewriter.ttc") format("truetype");
}

/* From https://css-tricks.com/a-couple-of-use-cases-for-calc/ */
/* So 100% means 100% */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding-bottom: 100px;
  font-family: "Nexa Extra Light", sans-serif;

  color: white;

  background: #d10007;

  &.embedded {
    background-color: black;
  }
}

a,
a:visited {
  color: white;
}

#sharing .fb_iframe_widget span {
  vertical-align: baseline !important;
}

/*#header {
  width: 100%;
  height: 64px;
  background-color: #82324F;
  color: #F1D300;
  text-align: center;
}*/

#game-parent {
  position: relative;

  height: 540px; /*! fallback *!*/
  height: calc((9 / 16) * 100vw);
  max-height: calc(100vh - 50px);

  /*width: 100%;*/

  /* height: calc((16 / 9) * 100vw);
  max-height: calc(100vh - 50px); */

  /* This background color will be overridden by the image */
  // background-color: #000000;

  // background-image: url(../images/loader_bg.png);
  // background-size: cover;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:fullscreen,
  &:-webkit-full-screen,
  &:-moz-full-screen,
  &:-ms-fullscreen {
    max-height: unset;

    canvas {
      max-height: 100vh;

      top: 50%;

      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }

  canvas {
    position: absolute;

    /*height: 540px; !* fallback *!*/
    /*height: calc((9 / 16) * 100vw);*/

    height: calc((9 / 16) * 100vw);
    max-height: calc(100vh - 50px);

    /* Center the element
       Based on https://css-tricks.com/centering-percentage-widthheight-elements/ */
    left: 50%;

    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);

    background-color: #40afc2;

    outline: none;
  }
}

.embedded #game-parent {
  height: calc((9 / 16) * 100vw);
  max-height: 100vh;

  background-color: unset;
  background-image: unset;

  canvas {
    height: calc((9 / 16) * 100vw);
    max-height: 100vh;
  }
}

#html-layer {
  position: relative;

  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  pointer-events: none;

  > * {
    pointer-events: auto;

    font-family: "Jura";
    color: white;
  }

  input[type="radio"] {
    width: 20px;
    height: 20px;
  }
}

#descriptive-content {
  margin: 0;

  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  // justify-content: space-between;
  // div {
  //   font-size: small;
  //   text-align: right;
  // }

  @media (min-width: 768px) {
    margin: 1rem;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  * {
    margin: 0;
  }

  > :first-child {
    @media (min-width: 768px) {
      text-align: left;
    }
  }

  > :nth-child(2) {
    @media (min-width: 768px) {
      text-align: right;
    }
  }
}

#browser-warning {
  font-size: small;
}

.build-info {
  font-size: x-small;
}

#intro-video {
  margin: 50px 240px;
  border: 4px solid white;
  border-radius: 100;
}

#play-button {
  background: url("../images/play.png") no-repeat;
  // background-size: 220px 220px;

  position: absolute;
  width: 220px;
  height: 220px;

  left: 830px;
  top: 350px;
  cursor: pointer;
}
